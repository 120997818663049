<template>
  <div>
    <!-- 设置设备参数 -->
    <a-modal title="控制设备" v-model="visible" @ok="handleOk">
      <div class="property-name">
        <div>{{property.name}} {{dataRange}}</div>
        <a-tag color="green"> {{property.code}}</a-tag>
      </div>

      <a-input v-if="isTypeInput" placeholder v-model="value"/>

      <a-select v-else style="width: 100%" v-model="value">
        <template v-for="item in property.specs">
          <a-select-option :value="item.value">{{item.name}} - {{item.value}}</a-select-option>
        </template>
      </a-select>

    </a-modal>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        value: '',
        property: {},
        onSetPara:null,
        isTypeInput:true
      }
    },
    computed:{
      dataRange(){
        if(this.property.specs.step){
          return `(${this.property.specs.min}~${this.property.specs.max}${this.property.specs.unit})`
        }
        return ""
      }
    },
    methods: {
      show (property,value,onSetPara) {
        this.property = property
        if(property.dataType=="STRUCT"){
          this.value = JSON.stringify(value)
        }else{
          this.value=value
        }
        let type = property.dataType
        this.isTypeInput = !(type=="BOOL" || type == "ENUM")
        this.onSetPara = onSetPara
        this.visible = true
      },
      handleOk () {
        if(this.onSetPara){
          if(this.property.dataType=="BOOL"){
            this.onSetPara(this.value=="true")
          }else{
            this.onSetPara(this.value)
          }
        }
        this.visible=false
      }
    }
  }
</script>

<style scoped>
  .property-name {
    font-size: 20px;
    margin-bottom: 20px;
  }
</style>
